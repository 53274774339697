<template>
  <base-material-card
    v-if="campos.length || baseDados === ''"
    color="primary"
    icon="mdi-filter"
    inline
    class="px-5 py-3"
  >
    <template v-slot:after-heading>
      <div class="display-1 font-weight-light">Filtros</div>
    </template>
    <v-container
      fluid
      class="py-0 mt-7"
    >
      <v-row
        :class="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs ? 'mt-6' : ''"
        v-for="(filtro, index) in filtros"
        :key="index"
      >
        <v-col
          md="3"
          cols="12"
          class="py-0"
        >
          <v-autocomplete
            class="mt-5"
            :items="camposOrdenados"
            item-text="column"
            v-model="filtro.campo"
            @change="limparTerceiroGrupoInputsValores(filtro, index)"
            @input="(campo) => updateFiltroType(filtro, campo)"
            dense
            label="Campo"
            no-data-text="Nenhum campo disponível"
            :loading="loading"
            :disabled="loading"
          />
        </v-col>
        <v-col
          md="3"
          cols="12"
          class="py-0"
        >
          <v-autocomplete
            :items="filtrosDisponiveisToCampo(filtro.campo)"
            v-model="filtro.funcao"
            @change="limparTerceiroGrupoInputsValores(filtro, index)"
            item-value="value"
            item-text="text"
            label="Filtro"
            no-data-text="Nenhum filtro disponível"
            :loading="loading"
            :disabled="loading"
          />
        </v-col>
        <v-col
          md="5"
          sm="11"
          cols="10"
          class="py-0"
        >
          <input-group-valor
            :ref="`refInpoutGroupValor${index}`"
            :index="index"
            :filtro.sync="filtros[index]"
            :loading="loading"
          />
        </v-col>
        <v-col
          sm="1"
          cols="2"
          class="mt-2 py-0"
          id="btn-add-remove-filtro"
        >
          <v-row>
            <v-col cols="6">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="#2B60D6"
                    min-width="0"
                    icon
                    fab
                    x-small
                    @click="addFiltro(index)"
                    v-bind="attrs"
                    v-on="on"
                    :loading="loading"
                  >
                    <v-icon small> mdi-plus </v-icon>
                  </v-btn>
                </template>
                <span>Adicionar Filtro</span>
              </v-tooltip>
            </v-col>
            <v-col cols="6">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="index > 0"
                    color="error"
                    min-width="0"
                    icon
                    fab
                    x-small
                    @click="removeFiltro(index)"
                    v-bind="attrs"
                    v-on="on"
                    :loading="loading"
                  >
                    <v-icon small>mdi-minus</v-icon>
                  </v-btn>
                </template>
                <span>Remover Filtro</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </base-material-card>
</template>

<script>
import dayjs from 'dayjs';
import dateFormatterMixins from '@/mixins/dateFormatterMixins';
import BasesDadosService from '@/services/BasesDadosService';

export default {
  mixins: [dateFormatterMixins],

  components: {
    InputGroupValor: () => import('@/components/relatorios/InputGroupValor')
  },

  props: {
    campos: {
      type: Array,
      default: () => []
    },
    relatorio: {
      type: Object,
      default: () => {}
    },
    baseDados: {
      type: String,
      default: ''
    }
  },

  data: () => ({
    filtros: [
      {
        campo: '',
        funcao: '',
        valor: '',
        type: '',
        valorFinal: '',
        date: '',
        date2: '',
        dateFormatted: '',
        dateFormatted2: '',
        month: '',
        monthFormatted: '',
        month2: '',
        monthFormatted2: ''
      }
    ],
    filtrosFactiveis: [
      {
        function: '=',
        translate: '=',
        types: [
          'boolean',
          'character',
          'character varying',
          'text',
          'smallint',
          'integer',
          'bigint',
          'numeric',
          'timestamp',
          'date'
        ]
      },
      {
        function: '≠',
        translate: '<>',
        types: [
          'boolean',
          'character',
          'character varying',
          'text',
          'smallint',
          'integer',
          'bigint',
          'numeric',
          'timestamp',
          'date'
        ]
      },
      {
        function: '≥',
        translate: '>=',
        types: ['smallint', 'integer', 'bigint', 'numeric', 'timestamp', 'date']
      },
      {
        function: '≤',
        translate: '<=',
        types: ['smallint', 'integer', 'bigint', 'numeric', 'timestamp', 'date']
      },
      {
        function: '>',
        translate: '>',
        types: ['smallint', 'integer', 'bigint', 'numeric', 'timestamp', 'date']
      },
      {
        function: '<',
        translate: '<',
        types: ['smallint', 'integer', 'bigint', 'numeric', 'timestamp', 'date']
      },
      {
        function: 'Entre',
        translate: 'BETWEEN',
        types: ['smallint', 'integer', 'bigint', 'numeric', 'timestamp', 'date']
      },
      {
        function: 'Lista',
        translate: 'IN',
        types: [
          'smallint',
          'integer',
          'bigint',
          'character',
          'character varying',
          'text'
        ]
      },
      {
        function: 'Fora da Lista',
        translate: 'NOT IN',
        types: [
          'smallint',
          'integer',
          'bigint',
          'character',
          'character varying',
          'text'
        ]
      },
      {
        function: 'Vazio',
        translate: 'IS NULL',
        types: [
          'boolean',
          'character',
          'character varying',
          'text',
          'smallint',
          'integer',
          'bigint',
          'numeric',
          'timestamp',
          'date'
        ]
      },
      {
        function: 'Não Vazio',
        translate: 'IS NOT NULL',
        types: [
          'boolean',
          'character',
          'character varying',
          'text',
          'smallint',
          'integer',
          'bigint',
          'numeric',
          'timestamp',
          'date'
        ]
      },
      {
        function: 'Contém',
        translate: 'ILIKE %X%',
        types: ['character', 'character varying', 'text']
      },
      {
        function: 'Não contém',
        translate: 'NOT ILIKE %X%',
        types: ['character', 'character varying', 'text']
      },
      {
        function: 'Começa com',
        translate: 'ILIKE X%',
        types: ['character', 'character varying', 'text']
      },
      {
        function: 'Termina com',
        translate: 'ILIKE %X',
        types: ['character', 'character varying', 'text']
      }
    ],
    search: '',
    campoDataBase: 'mes_dados',
    finishGetRelatorioFiltros: false,
    loading: false
  }),

  computed: {
    camposOrdenados() {
      let campos = [...this.campos];
      return campos.sort((a, b) =>
        a.column.toUpperCase() > b.column.toUpperCase() ? 1 : -1
      );
    },
    userSelectedCompanyBdgdVersion() {
      return this.$store.getters.getUserSelectedCompanyBdgdVersion;
    }
  },

  methods: {
    filtrosDisponiveisToCampo(campo) {
      if (!campo) return [];

      let infoCampoSelecionado = this.campos.find((e) => e.column === campo);
      if (!infoCampoSelecionado) return [];

      let arrayFiltros = this.filtrosFactiveis.filter((el) =>
        el.types.includes(infoCampoSelecionado.type)
      );
      return arrayFiltros.map((e) => {
        return { text: e.function, value: e.translate };
      });
    },

    updateFiltroType(filtro, campo) {
      this.campos.forEach((item) => {
        if (item.column == campo) filtro.type = item.type;
      });
      this.$forceUpdate();
    },

    limparTerceiroGrupoInputsValores(filtro, index) {
      setTimeout(() => {
        filtro.date =
          filtro.date2 =
          filtro.dateFormatted =
          filtro.dateFormatted2 =
          filtro.month =
          filtro.month2 =
          filtro.monthFormatted =
          filtro.monthFormatted2 =
          filtro.valor =
          filtro.valorFinal =
            '';
        this.$refs['refInpoutGroupValor' + index][0].render();
        this.$forceUpdate();
      }, 400);
    },

    addFiltro(index) {
      this.filtros.push(this.getEmptyFilter());
      this.$refs['refInpoutGroupValor' + index][0].addMenus();
    },

    removeFiltro(target) {
      if (this.filtros.length <= 1) {
        this.filtros = [this.getEmptyFilter()];
      } else {
        this.filtros.splice(target, 1);
        this.$refs['refInpoutGroupValor' + target][0].removeMenus(target);
      }
    },

    getEmptyFilter() {
      return {
        campo: '',
        funcao: '',
        valor: '',
        type: '',
        valorFinal: '',
        date: '',
        date2: '',
        dateFormatted: '',
        dateFormatted2: '',
        month: '',
        monthFormatted: '',
        month2: '',
        monthFormatted2: ''
      };
    },

    validarFiltros(filtros) {
      let retorno = true;

      filtros.forEach((filtro) => {
        /* Caso o campo de data for do tipo month, ira validar se o valor corresponde a ##/#### e se é valido */
        if (
          filtro.monthFormatted &&
          (filtro.monthFormatted.indexOf('undefined') != -1 ||
            filtro.monthFormatted.length != 7)
        ) {
          this.$toast.warning(
            `O filtro [${filtro.campo}] deve estar no formato: ##/####.`,
            '',
            { position: 'topRight' }
          );
          retorno = false;
        } else if (
          filtro.monthFormatted2 &&
          (filtro.monthFormatted2.indexOf('undefined') != -1 ||
            filtro.monthFormatted2.length != 7)
        ) {
          this.$toast.warning(
            `O filtro [${filtro.campo}] deve estar no formato: ##/####.`,
            '',
            { position: 'topRight' }
          );
          retorno = false;
        }

        /* Caso o campo de data for do tipo date, ira validar se o valor corresponde a ##/##/#### e se é valido */
        if (
          filtro.dateFormatted &&
          (filtro.dateFormatted.indexOf('undefined') != -1 ||
            filtro.dateFormatted.length != 10)
        ) {
          this.$toast.warning(
            `O filtro [${filtro.campo}] deve estar no formato: ##/##/####.`,
            '',
            { position: 'topRight' }
          );
          retorno = false;
        } else if (
          filtro.dateFormatted2 &&
          (filtro.dateFormatted2.indexOf('undefined') != -1 ||
            filtro.dateFormatted2.length != 10)
        ) {
          this.$toast.warning(
            `O filtro [${filtro.campo}] deve estar no formato: ##/##/####.`,
            '',
            { position: 'topRight' }
          );
          retorno = false;
        }

        if (filtro.campo && filtro.funcao.trim() == '') {
          this.$toast.warning(
            `Função para utilização do filtro [${filtro.campo}] não informado.`,
            '',
            { position: 'topRight' }
          );
          retorno = false;
        }
      });

      return retorno;
    },

    exportCampos() {
      let isValid = this.validarFiltros(this.filtros);
      return isValid ? this.filtros : 'invalido';
    },

    translateClasse(classe) {
      switch (classe) {
        case 'inteiro':
        case 'float':
        case 'decimal':
        case 'number':
          return 'integer';
        case 'data':
          return 'date';
        default:
          return 'string';
      }
    },

    getRelatorioFiltros() {
      if (!this.relatorio.estrutura_json) return;

      const filtros = JSON.parse(this.relatorio.estrutura_json).condicoes;
      let obj = {};
      let i = 0;

      filtros.forEach((filtro) => {
        obj = {
          campo: filtro.campo,
          funcao: filtro.criterio,
          valor:
            filtro.criterio == 'IN' || filtro.criterio == 'NOT IN'
              ? filtro.valores.join('\n')
              : filtro.valores[0],
          type: this.translateClasse(filtro.classe),
          valorFinal: filtro.criterio == 'BETWEEN' ? filtro.valores[1] : '',
          date: filtro.classe == 'data' ? filtro.valores[0] : '',
          month:
            filtro.campo.toUpperCase() == this.campoDataBase.toUpperCase()
              ? filtro.valores[0]
              : '',
          date2:
            filtro.classe == 'data' && filtro.criterio == 'BETWEEN'
              ? filtro.valores[1]
              : '',
          month2:
            filtro.campo.toUpperCase() == this.campoDataBase.toUpperCase()
              ? filtro.valores[1]
              : '',
          dateFormatted:
            filtro.classe == 'data' ? this.formatDate(filtro.valores[0]) : '',
          monthFormatted:
            filtro.campo.toUpperCase() == this.campoDataBase.toUpperCase()
              ? this.formatMonth(filtro.valores[0])
              : '',
          dateFormatted2:
            filtro.classe == 'data' && filtro.criterio == 'BETWEEN'
              ? this.formatDate(filtro.valores[1])
              : '',
          monthFormatted2:
            filtro.campo.toUpperCase() == this.campoDataBase.toUpperCase()
              ? this.formatMonth(filtro.valores[1])
              : ''
        };
        this.filtros[i] = obj;

        if (!(filtro.criterio == 'IN' || filtro.criterio == 'NOT IN')) {
          this.filtros[i].valor = filtro.valores[0];
        }

        this.filtros[i].valorFinal = filtro.valores[1];
        this.$forceUpdate();
        i++;
      });
      this.finishGetRelatorioFiltros = true;
    },

    /**
     * Retorna data_registro e/ou bdgd_versao da ultima BDGD ou AIS importado(a)
     */
    getBaseDadosMaiorLancamento(
      baseDados,
      retornaMesDados = true,
      retornaBdgdVersao = false,
      entidade = ''
    ) {
      this.loading = true;
      this.$emit('loading', true);
      return BasesDadosService.getBaseDadosMaiorLancamento(
        baseDados,
        retornaMesDados,
        retornaBdgdVersao,
        entidade
      )
        .then((response) => response.data)
        .catch(() =>
          this.$toast.error(
            'Erro ao tentar pegar último mês dos dados e/ou versão.',
            '',
            { position: 'topRight' }
          )
        )
        .finally(() => {
          this.loading = false;
          this.$emit('loading', false);
        });
    },

    criarFiltrosDefault(dadosFiltro) {
      this.filtros = [];
      let emptyFilter = this.getEmptyFilter();

      if (JSON.stringify(dadosFiltro) == '[]') this.filtros[0] = emptyFilter;

      if ('mes_dados' in dadosFiltro) {
        this.filtros.push({
          ...emptyFilter,
          ...{
            campo: 'mes_dados',
            funcao: '=',
            type: 'date',
            month: this.formatMonthToPicker(dadosFiltro.mes_dados), //formato esperado - ####-##
            monthFormatted: this.formatMonth(dadosFiltro.mes_dados), //formato esperado - ##/####
            valor: dadosFiltro.mes_dados //formato esperado - ####-##-##
          }
        });
      }
      if ('bdgd_versao' in dadosFiltro) {
        this.filtros.push({
          ...emptyFilter,
          ...{
            campo: 'bdgd_versao',
            funcao: '=',
            type: 'integer',
            valor: dadosFiltro.bdgd_versao
          }
        });
      }
      this.$forceUpdate();
    }
  },

  watch: {
    campos() {
      if (!this.finishGetRelatorioFiltros && this.$route.params.id) {
        this.getRelatorioFiltros();
      }
    },

    async baseDados(newBaseDados) {
      if (!this.finishGetRelatorioFiltros && this.$route.params.id) return;

      let dadosFiltro = {};
      switch (newBaseDados) {
        // incluir aqui views que possuem coluna mes_dados
        case 'view_ais_consulta':
        case 'view_laudos_consulta':
        case 'view_conciliacao_ais':
        case 'view_indqual_indicadores':
          dadosFiltro = await this.getBaseDadosMaiorLancamento(
            newBaseDados,
            true
          );
          this.criarFiltrosDefault(dadosFiltro);
          break;
        // incluir aqui views que possuem colunas (mes_dados e bdgd_versao), que sua fonte de dados seja a view_resumo_bdgds e são entidades específicas */
        case 'view_arat':
        case 'view_bar':
        case 'view_base':
        case 'view_bay':
        case 'view_be':
        case 'view_conj':
        case 'view_crvcrg':
        case 'view_ctat':
        case 'view_ctmt':
        case 'view_ep':
        case 'view_eqcr':
        case 'view_eqme':
        case 'view_eqre':
        case 'view_eqse':
        case 'view_eqtrat':
        case 'view_eqtrm':
        case 'view_eqtrmt':
        case 'view_pip':
        case 'view_pnt':
        case 'view_ponnot':
        case 'view_pt':
        case 'view_ramlig':
        case 'view_segcon':
        case 'view_ssdat':
        case 'view_ssdbt':
        case 'view_ssdmt':
        case 'view_sub':
        case 'view_ucat':
        case 'view_ucbt':
        case 'view_ucmt':
        case 'view_ugat':
        case 'view_ugbt':
        case 'view_ugmt':
        case 'view_uncrat':
        case 'view_uncrbt':
        case 'view_uncrmt':
        case 'view_unreat':
        case 'view_unremt':
        case 'view_unseat':
        case 'view_unsebt':
        case 'view_unsemt':
        case 'view_untrat':
        case 'view_untrmt':
        case 'view_georreferenciado':
          if (this.userSelectedCompanyBdgdVersion) {
            const { data_registro, versao } =
              this.userSelectedCompanyBdgdVersion;
            dadosFiltro = {
              mes_dados: dayjs(data_registro).format('YYYY-MM-DD'),
              bdgd_versao: versao
            };
          } else {
            dadosFiltro = await this.getBaseDadosMaiorLancamento(
              'view_resumo_bdgds',
              true,
              true,
              newBaseDados.replace('view_', '')
            );
          }
          this.criarFiltrosDefault(dadosFiltro);
          break;

        /* incluir aqui views que possuem colunas (mes_dados e bdgd_versao), que sua fonte de dados seja a view_resumo_bdgds e NÃO são entidades específicas */
        case 'view_bdgd':
        case 'view_bdgd_sumarizada':
        case 'view_conciliacao_bdgd_tuc_125':
        case 'view_conciliacao_bdgd_tuc_135':
        case 'view_conciliacao_bdgd_tuc_160':
        case 'view_conciliacao_bdgd_tuc_190':
        case 'view_conciliacao_bdgd_tuc_210':
        case 'view_conciliacao_bdgd_tuc_255':
        case 'view_conciliacao_bdgd_tuc_295':
        case 'view_conciliacao_bdgd_tuc_330':
        case 'view_conciliacao_bdgd_tuc_340':
        case 'view_conciliacao_bdgd_tuc_345':
        case 'view_conciliacao_bdgd_tuc_395':
        case 'view_conciliacao_bdgd_tuc_565':
        case 'view_conciliacao_bdgd_tuc_570':
        case 'view_conciliacao_bdgd_tuc_575':
        case 'view_conciliacao_bdgd_tuc_580':
        case 'view_historico_erros_testes':
        case 'view_samp_balanco_energia':
        case 'view_samp_fornecimento_energia':
        case 'view_samp_receita_uso':
          if (this.userSelectedCompanyBdgdVersion) {
            const { data_registro, versao } =
              this.userSelectedCompanyBdgdVersion;
            dadosFiltro = {
              mes_dados: dayjs(data_registro).format('YYYY-MM-DD'),
              bdgd_versao: versao
            };
          } else {
            dadosFiltro = await this.getBaseDadosMaiorLancamento(
              'view_resumo_bdgds',
              true,
              true
            );
          }
          this.criarFiltrosDefault(dadosFiltro);
          break;

        default:
          this.filtros = [];
          this.filtros[0] = this.getEmptyFilter();
      }
      this.$forceUpdate();
    }
  }
};
</script>
